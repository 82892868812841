/*
 * Artist List.
 */
.artist-list {
	position: relative;
}

@media (max-width: 1279px) {
	.artist-list {
		padding-bottom: calc(100% / 3 * 2);
	}
}

@media (min-width: 1280px) {
	.artist-list {
		padding-right: 75%;
		min-height: 620px;
	}
}

/*
 * Artist List — List.
 */
.artist-list__list {
	display: inline-block;
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (max-width: 1279px) {
	.artist-list__list {
		margin-bottom: var(--space-50);
	}
}

/*
 * Artist List — Link.
 */
.artist-list__link {
	color: currentColor;
	display: inline-block;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	text-decoration: none;
}

@media (min-width: 1280px) {
	.artist-list__link {
		padding-right: var(--space-20);
	}
}

/*
 * Artist List — Link — Modifiers.
 */
.artist-list__item:first-child .artist-list__link {
	padding-top: 0;
}

.artist-list__item:last-child .artist-list__link {
	padding-bottom: 0;
}

/*
 * Artist List — Link — Label.
 */
.artist-list__link__label {
	pointer-events: none;
}

/*
 * Artist List — Link — Label — Modifiers.
 */
.artist-list__link:focus .artist-list__link__label,
.artist-list__link:hover .artist-list__link__label {
	display: inline-block;
	transform: rotate(6deg);
}

/*
 * Artist List — Thumbnail Containers.
 */
.artist-list__thumbnail-container,
.artist-list__archive-thumbnail-container {
	content: "";
	display: block;
	padding-top: calc(100% / 3 * 2);
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
}

@media (min-width: 1280px) {
	.artist-list__thumbnail-container,
	.artist-list__archive-thumbnail-container {
		padding-top: 620px;
		top: 0;
		bottom: auto;
		width: 75%;
	}
}

.artist-list__thumbnail-container {
	display: none;
}

/*
 * Artist List — Thumbnail Containers — Modifiers.
 */
.artist-list__link:focus + .artist-list__thumbnail-container,
.artist-list__link:hover + .artist-list__thumbnail-container {
	display: block;
}

.artist-list__list:focus-within + .artist-list__archive-thumbnail-container {
	display: none;
}

.artist-list__list:hover + .artist-list__archive-thumbnail-container {
	display: none;
}

/*
 * Artist List — Thumbnails.
 */
.artist-list__thumbnail,
.artist-list__archive-thumbnail {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	height: 100%;
}

/*
 * Artist List — Thumbnails — Modifiers.
 */
.artist-list__thumbnail--fill-width,
.artist-list__archive-thumbnail--fill-width {
	width: 100%;
	height: auto;
}
