.exhibition-header {
}

@media (min-width: 568px) {
	.exhibition-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

/* Titles. */
.exhibition-header__titles {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.exhibition-header__titles * {
	flex-grow: 0;
	flex-shrink: 0;
	margin-top: 0;
	margin-bottom: 0;
}

/* Exhibition title. */
.exhibition-header__exhibition-title {
	order: 2;
}

/* Artists. */
.exhibition-header__artists {
	order: 1;
}

.exhibition-header__locations {
	order: 3;
}

@media (max-width: 567px) {
	.exhibition-header__titles:not(:last-child) {
		margin-bottom: var(--space-50);
	}
}

@media (min-width: 568px) {
	.exhibition-header__titles {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

/* Locations. */
.exhibition-header__locations {
	color: var(--color-foreground-muted);
}

/* Dates. */
@media (min-width: 568px) {
	.exhibition-header__dates {
		flex-basis: 25%;
		flex-grow: 0;
		flex-shrink: 0;
		text-align: right;
	}
}
