.site-footer {
	border-top: 1px solid;
	color: var(--color-foreground-muted);
	padding-top: var(--space-50);
	padding-bottom: var(--space-50);
}

@media (min-width: 768px) {
	.site-footer {
		display: flex;
		align-items: flex-start;
	}
}

@media (min-width: 1280px) {
	.site-footer {
		padding-top: var(--space-100);
		padding-bottom: var(--space-100);
	}
}

/* Columns. */
@media (max-width: 767px) {
	.site-footer__column:first-child {
		margin-bottom: var(--space-50);
	}
}

@media (min-width: 768px) {
	.site-footer__column {
		flex-basis: calc(50% - (var(--space-20) / 2));
	}

	.site-footer__column:first-child {
		margin-right: var(--space-20);
	}
}

@media (min-width: 1280px) {
	.site-footer__column {
		flex-basis: calc(50% - (var(--space-40) / 2));
	}

	.site-footer__column:first-child {
		margin-right: var(--space-40);
	}
}

/* Icon links. */
.site-footer__icon-links {
	font-size: 0 !important;
}

.site-footer__icon-link {
	display: inline-block;
	opacity: 0.55;
	text-decoration: none;
	width: 20px;
	height: 20px;
}

.site-footer__icon-link:not(:last-child) {
	margin-right: var(--space-20);
}

.site-footer__icon-link img {
	display: block;
	width: 100%;
	height: 100%;
}

.site-footer__icon-link:focus,
.site-footer__icon-link:hover {
	opacity: 1;
}
