/*
 * Button.
 * Remark: Typography of .button can be found in typography.css.
 */
.button {
	appearance: none;
	background-color: var(--color-foreground);
	border: 0 solid;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-background);
	display: inline-block;
	letter-spacing: normal;
	outline: 0;
	padding: 0.3125rem var(--space-20);
	text-decoration: none;
	transition:
		background-color 96ms linear,
		color 96ms linear;
}

@media (min-width: 1280px) {
	.button {
		padding: var(--space-10) var(--space-40);
	}
}

/* Button — States. */
.button:disabled {
	background-color: var(--color-foreground-muted);
	cursor: not-allowed;
}

.button[hidden] {
	display: none;
}

.button:not(:disabled):hover {
	background-color: var(--color-foreground-hover);
}

.button:not(:disabled):focus {
	box-shadow: 0 0 6px 0 var(--color-focus);
}

.button:not(:disabled):not(:focus-visible):focus {
	box-shadow: none;
}
