.l-error {
	box-sizing: border-box;
	padding: var(--space-20);
	width: 100%;
}

@media (min-width: 1280px) {
	.l-error {
		padding: var(--space-100);
	}
}

/* Logo. */
.l-error__logo {
	color: currentColor;
	display: block;
	margin-bottom: var(--space-20);
	text-decoration: none;
}

.l-error__logo svg {
	display: block;
	width: 126px;
	height: 45px;
}

@media (min-width: 1280px) {
	.l-error__logo {
		margin-bottom: var(--space-100);
	}

	.l-error__logo svg {
		width: 140px;
		height: 50px;
	}
}

/* Title + content. */
@media (min-width: 768px) {
	.l-error__title,
	.l-error__content {
		max-width: 340px;
	}
}

/* Title. */
.l-error__title {
	display: inline-block;
	margin-bottom: var(--space-50);
}
