.post-header {
}

@media (min-width: 568px) {
	.post-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

/* Titles. */
.post-header__titles * {
	margin-top: 0;
	margin-bottom: 0;
}

@media (max-width: 567px) {
	.post-header__titles:not(:last-child) {
		margin-bottom: var(--space-50);
	}
}

@media (min-width: 568px) {
	.post-header__titles {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

/* Locations. */
.post-header__locations {
	color: var(--color-foreground-muted);
}

/* Dates. */
@media (min-width: 568px) {
	.post-header__date {
		flex-basis: 25%;
		flex-grow: 0;
		flex-shrink: 0;
		text-align: right;
	}
}
