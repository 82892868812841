.pagination {
}

/* List. */
.pagination__list {
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: stretch;
	align-items: center;
}

/* List item. */
.pagination__list li:not(:last-child) {
	margin-bottom: var(--space-10);
}

@media (min-width: 1280px) {
	.pagination__list li:not(:last-child) {
		margin-bottom: var(--space-20);
	}
}

/* Links. */
.pagination__list a {
	text-align: center;
	min-width: 100px;
}

@media (min-width: 1280px) {
	.pagination__list a {
		min-width: 150px;
	}
}
