/*
 * Form.
 */
.form {
	max-width: 260px;
}

/* Hide all for children except the success area */
/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.form[state="success"] > *:not([success-area]) {
	display: none;
}

/*
 * Row.
 */
.form__row {
	width: 100%;
}

.form__row:not(:last-child) {
	margin-bottom: var(--space-20);
}

/*
 * Label.
 */
.form__label-container {
	display: block;
	margin-bottom: 0.3125rem;
	width: 100%;
}

.form__row--disabled .form__label {
	color: var(--color-foreground-muted);
	cursor: not-allowed;
}

/*
 * Error.
 */
.form__error {
	color: var(--color-error);
	font-family: var(--font-roboto);
	font-size: 0.8125rem;
	font-stretch: normal;
	font-style: italic;
	font-weight: var(--font-weight-regular);
	letter-spacing: normal;
	margin-top: 0.25rem;
}

/*
 * Fields.
 */
.form__input,
.form__select,
.form__textarea {
	background-color: var(--color-background);
	border: 1px solid var(--color-foreground-edges);
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	font-size: 1rem;
	outline: 0;
	padding: var(--space-10);
	transition:
		background-color 96ms linear,
		border-color 96ms linear,
		box-shadow 96ms linear;
	width: 100%;
}

/* Fields — Select. */
.form__select {
	appearance: none;
	background-image: url("../images/arrow-down.svg");
	background-position: calc(100% - var(--space-10)) center;
	background-repeat: no-repeat;
	background-size: 1rem;
	padding-right: calc(var(--space-10) + 1rem + var(--space-10)); /* Padding + arrow size + spacing. */
}

/* Fields — Textarea. */
.form__textarea {
	resize: vertical;
	height: 120px;
}

.form__textarea.form__textarea--small {
	height: 80px;
}

/* Fields — States. */
.form__input:hover,
.form__select:hover,
.form__textarea:hover {
	border-color: var(--color-foreground);
}

.form__input:focus,
.form__select:focus,
.form__textarea:focus {
	border-color: var(--color-foreground);
	box-shadow: 0 0 6px 0 var(--color-focus);
}

.form__input:disabled,
.form__select:disabled,
.form__textarea:disabled {
	background-color: var(--color-foreground-muted);
	border-color: transparent;
	cursor: not-allowed;
}

.form__row--error .form__input,
.form__row--error .form__select,
.form__row--error .form__textarea {
	border-color: var(--color-error);
}

/* Fields — Pseudo elements. */
.form__input::placeholder {
	color: var(--color-foreground-muted);
}

.form__select::placeholder {
	color: var(--color-foreground-muted);
}

.form__textarea::placeholder {
	color: var(--color-foreground-muted);
}

.form__select::-ms-expand {
	display: none;
}

/*
 * Checkboxes and radios.
 */
.form__checkbox:not(:last-child),
.form__radio:not(:last-child) {
	margin-bottom: var(--space-10);
}

.form__checkbox__input,
.form__radio__input {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

.form__checkbox__label,
.form__radio__label {
	color: currentColor;
	cursor: pointer;
	display: inline-block;
	line-height: 1.38;
	margin-left: calc(0.75rem + var(--space-20)); /* Checkbox spacing + width. */
	position: relative;
}

/* Checkboxes and radios — States. */
.form__checkbox__input:disabled + .form__checkbox__label,
.form__radio__input:disabled + .form__radio__label {
	color: var(--color-foreground-muted);
	cursor: not-allowed;
}

/* Checkboxes and radios — Pseudo elements. */
.form__checkbox__label::before,
.form__radio__label::before {
	border: 1px solid var(--color-foreground-edges);
	content: "";
	position: absolute;
	top: 0;
	left: calc((0.75rem + var(--space-20)) * -1); /* Checkbox spacing + width. */
	transition: border 96ms linear;
	width: calc(var(--space-20) - 0.125rem); /* width - border */
	height: calc(var(--space-20) - 0.125rem); /* width - border */
}

.form__checkbox__label::after,
.form__radio__label::after {
	background-color: currentColor;
	box-sizing: border-box;
	content: "";
	opacity: 0;
	position: absolute;
	top: 0.25rem;
	left: calc((0.75rem + var(--space-20) - 0.25rem) * -1); /* Checkbox spacing + width - offset. */
	transition: opacity 96ms linear;
	width: 0.75rem;
	height: 0.75rem;
}

.form__checkbox__label::after,
.form__checkbox__label::before,
.form__radio__label::after,
.form__radio__label::before {
	transform: translateY(-1px); /* vertically align with label */
}

@media (min-width: 1280px) {
	.form__checkbox__label::after,
	.form__checkbox__label::before,
	.form__radio__label::after,
	.form__radio__label::before {
		transform: translateY(1px); /* vertically align with label */
	}
}

.form__radio__label::after,
.form__radio__label::before {
	border-radius: 50%;
}

/* Checkboxes and radios — Pseudo elements — States. */
.form__checkbox__input:disabled + .form__checkbox__label::before,
.form__radio__input:disabled + .form__radio__label::before {
	border-color: var(--color-foreground-muted);
	cursor: not-allowed;
}

.form__checkbox__input:focus + .form__checkbox__label::before,
.form__radio__input:focus + .form__radio__label::before {
	box-shadow: 0 0 6px 0 var(--color-focus);
}

.form__checkbox__label:hover::before,
.form__checkbox__input:focus + .form__checkbox__label::before,
.form__radio__label:hover::before,
.form__radio__input:focus + .form__radio__label::before {
	border-color: var(--color-foreground);
}

.form__checkbox__input:disabled + .form__checkbox__label::after,
.form__radio__input:disabled + .form__radio__label::after {
	background-color: var(--color-foreground-disabled);
	cursor: not-allowed;
}

.form__checkbox__input:checked + .form__checkbox__label::after,
.form__radio__input:checked + .form__radio__label::after {
	opacity: 1;
}
