:root {
	/* Base colors. */
	--color-black: #000000;
	--color-white: #ffffff;
	--color-grey-dark: #303030;
	--color-grey-light: #b9b9b9;
	--color-grey-medium: #898989;
	--color-red: #ff220e;

	/* Colors : these need to be specific for older browsers, can't use vars */
	--color-background: #ffffff;
	--color-focus: #00a7ff;
	--color-foreground-disabled: #898989;
	--color-foreground-edges: #b9b9b9;
	--color-foreground-hover: #303030;
	--color-foreground-invisible: rgb(0 0 0 / 0);
	--color-foreground-muted: #797676; /* Closest color to the designed #b9b9b9 that has 4.5:1 contrast ratio. */
	--color-foreground: #000000;
	--color-error: #ff220e;

	/* Typography. */
	--font-eurostile: "Eurostile", "Arial Narrow", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	--font-roboto: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	/*  */
	--font-weight-regular: 400;
	--font-weight-bold: 700;

	/* Site header. */
	--site-header-height: 60px;
	--site-header-height-from-wide: 62px;

	/* Spaces. */
	--space-10: 0.625rem;
	--space-15: 0.9375rem;
	--space-20: 1.25rem;
	--space-30: 1.875rem;
	--space-40: 2.5rem;
	--space-50: 3.125rem;
	--space-100: 6.25rem;

	/* Z-indices. */
	--z-index-overlay: 102;
	--z-index-site-header-logo-hopstreet: 101;
	--z-index-site-header-logo-gallery: 100;
	--z-index-site-header-navigation: 99;
	--z-index-site-header-hamburger: 98;
	--z-index-site-header-background: 97;
}

@media (prefers-contrast: more) {
	:root {
		--color-background: var(--color-white);
		--color-foreground-disabled: var(--color-grey-medium);
		--color-foreground-edges: var(--color-grey-dark);
		--color-foreground-hover: var(--color-grey-medium);
		--color-foreground-muted: var(--color-grey-dark);
		--color-foreground: var(--color-black);
	}
}
