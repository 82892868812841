.wrapper {
	box-sizing: border-box;
	padding-right: var(--space-20);
	padding-left: var(--space-20);
	width: 100%;
}

@media (min-width: 1280px) {
	.wrapper {
		padding-right: calc((100% - 1240px) / 2);
		padding-left: calc((100% - 1240px) / 2);
	}
}
