.double-columns {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

@media (min-width: 568px) {
	.double-columns {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}
}

/* Item. */
@media (max-width: 567px) {
	.double-columns__item:not(:last-child) {
		margin-bottom: var(--space-50);
	}
}

@media (min-width: 568px) {
	.double-columns__item {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
		width: calc((100% - var(--space-20)) / 2);
	}
}

@media (min-width: 768px) {
	.double-columns__item {
		width: calc((100% - var(--space-40)) / 2);
	}
}
