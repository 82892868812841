.review-teaser {
	width: 100%;
}

/* Link. */
.review-teaser__link {
	color: currentColor;
	display: inline-block;
	text-decoration: none;
	width: 100%;
}

/* Inner. */
.review-teaser__inner {
	display: flex; /* So we can use 'order' for netter assistive tech readability. */
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
}

.review-teaser__inner * {
	width: 100%;
}

/* Thumbnail. */
.review-teaser__thumbnail {
	margin-bottom: var(--space-15);
	order: 1;
	transition: opacity 96ms linear;
	width: 100%;
	height: auto;
}

.review-teaser__link:focus .review-teaser__thumbnail,
.review-teaser__link:hover .review-teaser__thumbnail {
	opacity: 0.8;
}

/* Excerpt. */
.review-teaser__excerpt {
	margin-top: 0;
	margin-bottom: var(--space-10) !important;
	order: 2;
}

/* Footer. */
.review-teaser__footer {
	margin-top: 0;
	margin-bottom: 0;
	order: 3;
}

/* Source. */
.review-teaser__source {
	border-bottom: 1px solid var(--color-foreground-invisible);
	transition: border-bottom-color 96ms linear;
}

.review-teaser__link:focus .review-teaser__source,
.review-teaser__link:hover .review-teaser__source {
	border-bottom-color: var(--color-foreground);
}
