.l-site {
}

@supports (position: sticky) {
	.l-site::before {
		background-color: var(--color-background);
		content: "";
		position: fixed;
		width: 100%;
		height: var(--site-header-height);
		z-index: var(--z-index-site-header-background);
	}

	@media (min-width: 1280px) {
		.l-site::before {
			height: var(--site-header-height-from-wide);
		}
	}

	/*
	 * On small desktop screens where the height has been reduced we might want to unstick the navigation.
	 * The largest height of an image in the grid is ~580px and the site header scrolled has a height of 60px.
	 * Thus we're mustard cutting at 640px!
	 */
	@media (min-width: 1280px) and (max-height: 639px) {
		.l-site::before {
			display: none;
		}
	}
}

/*
 * The site-header has some overlap at the bottom.
 * Each page starts with a `section` so this overlap has to be cancelled.
 */
.l-site__content {
	margin-top: calc(-1 * var(--space-20));
}

@media (min-width: 1280px) {
	.l-site__content {
		margin-top: calc(-1 * var(--space-50));
	}
}
