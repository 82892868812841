.post-grid {
}

/**
 * 1 - General styles.
 */

/* 1a - List. */
.post-grid__list {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

/* 1b - Item. */
@media (max-width: 567px) {
	.post-grid__item:not(:last-child) {
		margin-bottom: var(--space-20);
	}
}

/**
 * 2 - Grid fallback styles.
 */

/* 2a - List. */
@media (min-width: 568px) {
	.post-grid__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}
}

/* 2b - Item. */
@media (min-width: 568px) {
	.post-grid__item {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: var(--space-20);
		width: calc((100% - var(--space-20)) / 2);
	}
}

@media (min-width: 768px) {
	.post-grid__item {
		width: calc((100% - (var(--space-40) * 2)) / 3);
	}
}

@media (min-width: 1280px) {
	.post-grid__item {
		margin-bottom: var(--space-40);
	}
}

/**
 * 3 - Grid supported styles.
 */
@supports (display: grid) and (grid-gap: 20px) and (grid-template-columns: 1fr 1fr) and (grid-template-rows: auto) {
	/* 3a - List. */
	@media (min-width: 568px) {
		.post-grid__list {
			display: grid;
			grid-gap: var(--space-20);
			grid-template-rows: auto;
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media (min-width: 768px) {
		.post-grid__list {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	@media (min-width: 1280px) {
		.post-grid__list {
			grid-gap: var(--space-40);
		}
	}

	/* 3b - Item. */
	@media (min-width: 568px) {
		.post-grid__item {
			margin-right: 0;
			margin-bottom: 0;
			width: 100%;
		}
	}
}
