.section {
	padding-top: var(--space-50);
	padding-bottom: var(--space-50);
	position: relative;
}

@media (min-width: 1280px) {
	.section {
		padding-top: var(--space-100);
		padding-bottom: var(--space-100);
	}
}

.section + .section {
	padding-top: 0;
}

/* Header. */
.section__header * {
	margin-top: 0;
	margin-bottom: 0;
}

.section__header:not(:last-child) {
	margin-bottom: var(--space-50);
}

@media (min-width: 1280px) {
	.section__header:not(:last-child) {
		margin-bottom: var(--space-100);
	}
}
