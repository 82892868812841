.anchor-navigation-list {
	font-size: 1rem;
}

.anchor-navigation-list ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (min-width: 1280px) {
	.anchor-navigation-list ul {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 50px;
	}
}

/* List-items. */
@media (max-width: 1279px) {
	.anchor-navigation-list__item:not(:last-child) {
		margin-bottom: 0.5rem;
	}
}

@media (min-width: 1280px) {
	.anchor-navigation-list__item:not(:first-child) {
		margin-left: var(--space-10);
	}

	.anchor-navigation-list__item:not(:last-child) {
		margin-right: var(--space-10);
	}
}

/* Links. */
.anchor-navigation-list__link {
	color: currentColor;
	display: inline-block;
	text-decoration: none;
}

.anchor-navigation-list__link:active,
.anchor-navigation-list__link:focus {
	transform: rotate(6deg);
}

.anchor-navigation-list__link:focus,
.anchor-navigation-list__link:hover {
	color: var(--color-foreground-hover);
}
