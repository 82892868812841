.review-grid {
}

/**
 * 1 - General styles.
 */

/* 1a - List. */
.review-grid__list {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

/**
 * 2 - Grid fallback styles.
 */

/* 2a - List. */
.review-grid__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
}

/* 2b - Item. */
.review-grid__item {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	margin-bottom: var(--space-20);
	width: calc((100% - var(--space-20)) / 2);
}

@media (min-width: 568px) {
	.review-grid__item {
		width: calc((100% - (var(--space-20) * 2)) / 3);
	}
}

@media (min-width: 768px) {
	.review-grid__item {
		width: calc((100% - (var(--space-20) * 3)) / 4);
	}
}

@media (min-width: 1280px) {
	.review-grid__item {
		margin-bottom: var(--space-40);
		width: calc((100% - (var(--space-40) * 3)) / 4);
	}
}

/**
 * 3 - Grid supported styles.
 */
@supports (display: grid) and (grid-gap: 20px) and (grid-template-columns: repeat(2, 1fr)) and (grid-template-rows: auto) {
	/* 3a - List. */
	.review-grid__list {
		display: grid;
		grid-gap: var(--space-20);
		grid-template-rows: auto;
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 568px) {
		.review-grid__list {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	@media (min-width: 768px) {
		.review-grid__list {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	@media (min-width: 1280px) {
		.review-grid__list {
			grid-gap: var(--space-40);
		}
	}

	/* 3b - Item. */
	.review-grid__item {
		margin-right: 0 !important;
		margin-bottom: 0 !important;
		width: 100% !important;
	}
}
