/*
 * Artwork Overlay.
 */
.artwork-overlay {
	box-sizing: border-box;
	padding: 3.75rem var(--space-20) var(--space-20) calc(var(--space-20) - 8px); /* Leave room for form focus outline. */
	position: relative;
	width: 100%;
	height: 100vh;
}

@media (min-width: 768px) {
	.artwork-overlay {
		padding: var(--space-50) var(--space-50) var(--space-50) calc(var(--space-50) - 8px);
	}
}

@media (min-width: 1440px) {
	.artwork-overlay {
		padding: var(--space-100) calc((100% - 1240px) / 2) var(--space-100) calc(((100% - 1240px) / 2) - 8px);
	}
}

/*
 * Close.
 */
.artwork-overlay__close {
	appearance: none;
	background-color: transparent;
	border: 0 solid;
	border-radius: 0;
	color: currentColor;
	cursor: pointer;
	padding: 0;
	position: absolute;
	top: 0;
	right: 50%;
	transform: translateX(50%);
	width: 3.75rem;
	height: 3.75rem;
}

@media (min-width: 768px) {
	.artwork-overlay__close {
		right: 0;
		transform: none;
		width: var(--space-50);
		height: var(--space-50);
	}
}

@media (min-width: 1440px) {
	.artwork-overlay__close {
		width: var(--space-100);
		height: var(--space-100);
	}
}

.artwork-overlay__close:focus:not(:focus-visible) {
	outline: 0;
}

.artwork-overlay__close__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: var(--space-20);
	height: var(--space-20);
}

.artwork-overlay__close__icon svg {
	display: block;
	width: 100%;
	height: 100%;
}
