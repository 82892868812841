.text-wrapper {
	box-sizing: border-box;
	padding-right: var(--space-20);
	padding-left: var(--space-20);
	width: 100%;
}

@media (min-width: 1024px) {
	.text-wrapper {
		padding-right: calc((100% - 900px) - var(--space-20));
	}
}

@media (min-width: 1280px) {
	.text-wrapper {
		padding-right: calc((100% - 900px) - ((100% - 1240px) / 2));
		padding-left: calc((100% - 1240px) / 2);
	}
}

/* Minify margins inside this wrapper. */
.text-wrapper > *:first-child {
	margin-top: 0;
}

.text-wrapper > *:last-child {
	margin-bottom: 0;
}

@media (min-width: 1280px) {
	.text-wrapper h1,
	.text-wrapper h2 {
		margin-top: var(--space-50);
		margin-bottom: var(--space-50);
	}
}
