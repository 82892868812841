/*
 * Logo.
 */
.site-header {
	/* for BEM structure */
}

.site-header__logo-gallery,
.site-header__logo-hopstreet {
	color: currentColor;
	padding-left: var(--space-20);
	text-decoration: none;
	width: 120px;
	height: 18px;
}

.site-header__logo-hopstreet {
	background-color: var(--color-background);
	box-sizing: content-box;
	display: block;
	float: left;
	padding-top: var(--space-20);
	padding-bottom: 2px;
}

@media (min-width: 1280px) {
	.site-header__logo-gallery,
	.site-header__logo-hopstreet {
		padding-left: calc((100% - 1240px) / 2);
		width: 145px;
		height: 22px;
	}

	.site-header__logo-hopstreet {
		padding-top: var(--space-100);
	}
}

@supports (position: sticky) {
	.site-header__logo-hopstreet {
		position: sticky;
		top: 0;
		z-index: var(--z-index-site-header-logo-hopstreet);
	}

	@media (min-width: 1280px) {
		.site-header__logo-hopstreet {
			top: calc(var(--space-20) - var(--space-100));
		}
	}
}

.site-header__logo-gallery {
	display: block;
	position: absolute;
	top: var(--space-40);
	z-index: var(--z-index-site-header-logo-gallery);
}

@media (min-width: 768px) {
	.site-header__logo-gallery {
		margin-top: -1rem;
		margin-bottom: var(--space-20);
		position: relative;
		top: 0;
	}
}

@media (min-width: 1280px) {
	.site-header__logo-gallery {
		margin-top: -0.875rem;
		margin-bottom: var(--space-50);
	}
}

/* Logo — Icon. */
.site-header__logo-gallery .icon,
.site-header__logo-hopstreet .icon {
	display: block;
	width: 120px;
	height: 18px;
}

@media (min-width: 1280px) {
	.site-header__logo-gallery .icon,
	.site-header__logo-hopstreet .icon {
		width: 145px;
		height: 22px;
	}
}

/*
 * Navigation.
 */
.site-header__navigation {
	background-color: var(--color-background);
	box-sizing: border-box;
	display: block;
	font-size: 1rem;
	padding: var(--space-20);
	position: relative;
	text-align: right;
	width: 100%;
	z-index: var(--z-index-site-header-navigation);
}

@media (min-width: 1280px) {
	.site-header__navigation {
		padding-top: var(--space-100);
		padding-right: calc((100% - 1240px) / 2);
	}
}

@supports (position: sticky) {
	@media (min-width: 768px) {
		.site-header__navigation {
			position: sticky;
			top: 0;
		}
	}

	@media (min-width: 1280px) {
		.site-header__navigation {
			top: calc(var(--space-20) - var(--space-100));
			transform: translateY(2px); /* vertical align center */
		}
	}
}

/*
 * Navigation List.
 */
.site-header__navigation-list {
	list-style: none;
	margin: 0;
}

/* Navigation List — Item. */
@media (max-width: 767px) {
	.site-header__navigation-list__item + .site-header__navigation-list__item {
		margin-top: 0.375rem;
	}
}

@media (min-width: 768px) {
	.site-header__navigation-list__item {
		display: inline-block;
	}

	.site-header__navigation-list__item + .site-header__navigation-list__item {
		margin-left: var(--space-20);
	}
}

/* Navigation List — Link. */
.site-header__navigation-list__link {
	color: currentColor;
	display: inline-block;
	outline: none;
	text-decoration: none;
}

/* Navigation List — Link — States. */
.site-header__navigation-list__link:focus,
.site-header__navigation-list__link:hover,
.site-header__navigation-list__link--is-active {
	transform: rotate(6deg);
}

/*
 * Hamburger.
 */
.site-header__hamburger {
	box-sizing: border-box;
	color: currentColor;
	display: none;
	padding: var(--space-20);
	position: fixed;
	text-decoration: none;
	top: 0;
	right: 0;
	width: 3.4375rem;
	z-index: var(--z-index-site-header-hamburger);
}

@supports (position: sticky) {
	@media (max-width: 767px) {
		.site-header__hamburger {
			display: block;
		}
	}
}

.site-header__hamburger .icon {
	display: inline-block;
	width: 16px;
	height: 24px;
}

/*
 * On small desktop screens where the height has been reduced we might want to unstick the navigation.
 * The largest height of an image in the grid is ~580px and the site header scolled has a height of 60px.
 * Thus we're mustart cutting at 640px!
 */
@supports (position: sticky) {
	@media (min-width: 1280px) and (max-height: 639px) {
		.site-header__logo-hopstreet,
		.site-header__navigation {
			position: relative;
			top: auto;
		}
	}
}
