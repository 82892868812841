/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
@media (prefers-reduced-motion: reduce) {
	*,
	*::after,
	*::before {
		animation-delay: -1ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		background-attachment: initial !important;
		scroll-behavior: auto !important;
		transition-delay: -1ms !important;
		transition-duration: 0.01ms !important;
	}
}

@media (min-width: 768px) {
	html {
		scroll-behavior: smooth;
	}
}

body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	position: relative;
	min-height: 100vh;
}

body.is-showing-overlay {
	overflow: hidden;
}

textarea {
	resize: none;
}

input,
button,
textarea,
select {
	font: inherit;
}

hr {
	background-color: var(--color-foreground-edges);
	border: none;
	height: 1px;
}

.section hr {
	margin-top: 0;
	margin-bottom: 0;
}

img,
video {
	display: block;
	max-width: 100%;
}

table,
th,
td {
	border: 1px solid var(--color-foreground-edges);
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	margin: 2rem 0;
	width: 100%;
	max-width: 100%;
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

th,
td {
	box-sizing: border-box;
	padding: 2rem;
	text-align: left;
}

ol:not([class]),
ul:not([class]),
dd:not([class]) {
	box-sizing: border-box;
	line-height: 1.38;
	margin: var(--space-20) 0;
	padding-left: var(--space-20);
}

@media (min-width: 1024px) {
	ol:not([class]),
	ul:not([class]),
	dd:not([class]) {
		margin-top: var(--space-50);
		margin-bottom: var(--space-50);
		padding-left: var(--space-30);
	}
}

ol:not([class]) ol:not([class]),
ol:not([class]) ul:not([class]),
ul:not([class]) ul:not([class]),
ul:not([class]) ol:not([class]) {
	margin-top: 0;
	margin-bottom: 0;
}

ol:not([class]):last-child,
ul:not([class]):last-child {
	margin-bottom: 0;
}

ol:not([class]),
ul:not([class]) {
	list-style: none;
}

ol:not([class]) {
	counter-reset: counter;
}

li:not([class]) {
	counter-increment: counter;
	padding-left: var(--space-15);
	position: relative;
}

@media (min-width: 1024px) {
	li:not([class]) {
		padding-left: 1.125rem;
	}
}

li:not([class])::before {
	position: absolute;
	left: 0;
}

ul:not([class]) li:not([class])::before {
	content: "- ";
}

ol:not([class]) li:not([class])::before {
	content: counter(counter);
}

a:not([class]) {
	color: currentColor;
	display: inline;
	text-decoration: underline;
}

a:not([class]):active,
a:not([class]):focus,
a:not([class]):hover {
	color: var(--color-foreground-hover);
	text-decoration: none;
}

p a:not([class]) {
	line-height: inherit;
}

*[targettable]:target::before {
	content: "";
	display: block;
	margin-top: calc(var(--site-header-height) * -1);
	pointer-events: none;
	visibility: hidden;
	height: var(--site-header-height);
}

@media (min-width: 1280px) {
	*[targettable]:target::before {
		margin-top: calc(var(--site-header-height-from-wide) * -1);
		height: var(--site-header-height-from-wide);
	}
}
