.teaser {
	width: 100%;
}

/* Link. */
.teaser__link {
	color: currentColor;
	display: block;
	text-decoration: none;
	width: 100%;
}

/* Thumbnail. */
.teaser__thumbnail {
	margin-bottom: 14px;
	transition: opacity 96ms linear;
	width: 100%;
	height: auto;
}

.teaser__link:focus .teaser__thumbnail,
.teaser__link:hover .teaser__thumbnail {
	opacity: 0.8;
}

/* All textual content below thumbnail. */
.teaser__thumbnail ~ * {
	margin-top: 0;
	margin-bottom: 4px;
}

.teaser__thumbnail ~ *:last-child {
	margin-bottom: 0;
}

/* Title. */
.teaser__title {
	border-bottom: 1px solid var(--color-foreground-invisible);
	transition: border-bottom-color 96ms linear;
}

.teaser__link:focus .teaser__title,
.teaser__link:hover .teaser__title {
	border-bottom-color: var(--color-foreground);
}

/* Subtitle. */
.teaser__subtitle {
	color: var(--color-foreground-muted);
}
