* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-roboto);
	font-size: 0.875rem;
}

@media (min-width: 1280px) {
	body {
		font-size: 1rem;
	}
}

blockquote,
em,
i {
	font-style: italic;
}

b,
strong {
	font-weight: var(--font-weight-bold);
}

h1,
h2 {
	margin-top: var(--space-50);
	margin-bottom: var(--space-50);
}

@media (min-width: 1280px) {
	h1,
	h2 {
		margin-top: var(--space-100);
		margin-bottom: var(--space-100);
	}
}

h3,
h4,
h5,
h6,
p {
	margin-top: var(--space-20);
	margin-bottom: var(--space-20);
}

@media (min-width: 1280px) {
	h3 {
		margin-top: var(--space-50);
		margin-bottom: var(--space-50);
	}
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child {
	margin-bottom: 0;
}

h1:only-child,
h2:only-child,
h3:only-child,
h4:only-child,
h5:only-child,
h6:only-child,
p:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

/**
 * Names used in Sketch design files:
 * - Mobile/H2
 * - Desktop/H2
 */
h1:not([class*="type-"]),
h2:not([class*="type-"]),
.type-a {
	font-family: var(--font-eurostile);
	font-size: 1.4375rem;
	font-stretch: condensed;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: 0.04375rem;
	line-height: normal;
	text-transform: uppercase;
}

@media (min-width: 1280px) {
	h1:not([class*="type-"]),
	h2:not([class*="type-"]),
	.type-a {
		font-size: 1.875rem;
		letter-spacing: normal;
	}
}

/**
 * Names used in Sketch design files:
 * - Mobile/H3
 * - Desktop/H3
 */
h3:not([class*="type-"]),
.type-b {
	font-family: var(--font-eurostile);
	font-size: 1.125rem;
	font-stretch: condensed;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: 0.04375rem;
	line-height: normal;
	text-transform: uppercase;
}

@media (min-width: 1280px) {
	h3:not([class*="type-"]),
	.type-b {
		font-size: 1.375rem;
		letter-spacing: normal;
	}
}

/**
 * Names used in Sketch design files:
 * - Mobile/H4
 * - Desktop/H4
 */
h4:not([class*="type-"]),
.type-c {
	font-family: var(--font-roboto);
	font-size: 0.875rem;
	font-stretch: normal;
	font-style: italic;
	font-weight: var(--font-weight-bold);
	letter-spacing: normal;
	line-height: normal;
}

@media (min-width: 1280px) {
	h4:not([class*="type-"]),
	.type-c {
		font-size: 1rem;
	}
}

/**
 * Names used in Sketch design files:
 * - Desktop/Body
 * - Desktop/ArtistList
 * - Button/Text/White
 * - Mobile/Body
 * - Button/Text/White/Mobile
 * - Mobile/ArtistList
 */
button:not([class*="type-"]),
.button,
p:not([class*="type-"]),
.type-d {
	font-family: var(--font-roboto);
	font-size: 0.875rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	letter-spacing: normal;
	line-height: 1.38;
}

@media (min-width: 1280px) {
	button:not([class*="type-"]),
	.button,
	p:not([class*="type-"]),
	.type-d {
		font-size: 1rem;
	}
}

/**
 * Names used in Sketch design files:
 * - Button/Text/DarkGrey
 * - Button/Text/DarkGrey/Mobile
 */
button:not([class*="type-"]):disabled,
.button:disabled,
.type-e {
	font-family: var(--font-roboto);
	font-size: 0.875rem;
	font-stretch: normal;
	font-style: italic;
	font-weight: var(--font-weight-regular);
	letter-spacing: normal;
	line-height: 1.38;
}

@media (min-width: 1280px) {
	button:not([class*="type-"]):disabled,
	.button:disabled,
	.type-e {
		font-size: 1rem;
	}
}

/**
 * Names used in Sketch design files:
 * - Desktop/H5
 * - Mobile/H5
 */
h5:not([class*="type-"]),
h6:not([class*="type-"]),
th:not([class*="type-"]),
.type-f {
	font-family: var(--font-roboto);
	font-size: 0.75rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.0625rem;
	line-height: normal;
	text-transform: uppercase;
}

@media (min-width: 1280px) {
	h5:not([class*="type-"]),
	h6:not([class*="type-"]),
	th:not([class*="type-"]),
	.type-f {
		font-size: 0.8125rem;
	}
}
