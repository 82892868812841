@media (min-width: 768px) and (max-width: 1279px) {
	.text-columns {
		max-width: 75vw;
	}
}

@media (min-width: 1280px) {
	.text-columns {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

/* Items. */
.text-columns__item:first-child {
	margin-bottom: var(--space-20);
}

@media (min-width: 768px) {
	.text-columns__item:first-child {
		margin-bottom: var(--space-50);
	}
}

@media (min-width: 1280px) {
	.text-columns__item:first-child {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: var(--space-40);
		margin-bottom: 0;
	}

	.text-columns__item:nth-child(2) {
		flex-basis: 25%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}
