.image-grid {
}

/* Row. */
@media (min-width: 768px) {
	.image-grid__row {
		display: flex;
	}
}

.image-grid__row + .image-grid__row {
	margin-top: var(--space-20);
}

@media (min-width: 1280px) {
	.image-grid__row + .image-grid__row {
		margin-top: var(--space-40);
	}
}

/* Item. */
.image-grid__item {
	display: block;
}

@media (max-width: 767px) {
	.image-grid__item ~ .image-grid__item {
		margin-top: var(--space-20);
	}
}

@media (min-width: 768px) {
	.image-grid__item {
		flex-grow: 0;
		flex-shrink: 1;
	}

	.image-grid__item ~ .image-grid__item {
		margin-left: var(--space-20);
	}
}

@media (min-width: 1280px) {
	.image-grid__item ~ .image-grid__item {
		margin-left: var(--space-40);
	}
}

/* Button. */
.image-grid__button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	cursor: pointer;
	display: block;
	padding: 0;
	transition: opacity 96ms linear;
	width: 100%;
}

.image-grid__button:focus,
.image-grid__button:hover {
	opacity: 0.8;
}

.image-grid__button:focus:not(:focus-visible) {
	outline: 0;
}

/* Image. */
.image-grid__image {
	width: 100%;
	max-width: none;
	height: auto;
}

@media (min-width: 768px) {
	.image-grid__image {
		width: auto;
		height: auto;
	}
}
