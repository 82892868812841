/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.artwork-carousel {
	display: block;
	position: static; /* Should not be relative. */
	width: 100%;
	height: 100%;
}

/* Items. */
.artwork-carousel__items {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	align-items: center;
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
}

/* Item. */
.artwork-carousel__item {
	box-sizing: border-box;
	display: none;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
	width: 100%;
	height: 100%;
}

.artwork-carousel__item[data-carousel-item-current] {
	display: block;
}

@media (min-width: 1280px) {
	.artwork-carousel__item {
		padding-right: 75%;
		max-height: calc((100vw - 100px) * 0.75 / 3 * 2); /* Thumbnail container width / aspect ratio. */
	}
}

@media (min-width: 1440px) {
	.artwork-carousel__item {
		max-height: 620px;
	}
}

/* Item inner. */
@media (min-width: 1280px) {
	.artwork-carousel__item-inner {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: auto;
		min-height: 100%;
	}
}

/* Meta. */
.artwork-carousel__item-meta {
	box-sizing: border-box;
	margin-bottom: var(--space-30);
	padding-left: 8px; /* Room for form field focus outlines. */
	width: 100%;
}

@media (min-width: 768px) {
	.artwork-carousel__item-meta {
		margin-bottom: var(--space-50);
	}
}

@media (min-width: 1280px) {
	.artwork-carousel__item-meta {
		margin-bottom: 0;
		padding-right: calc(var(--space-50) - 8px);
	}

	.artwork-carousel__item-meta--has-form {
		padding-bottom: 8px; /* Room for enquire button focus outlines. */
	}
}

/* Meta — Enquire. */
.artwork-carousel__item-meta__form-toggle {
	appearance: none;
	background-color: transparent;
	border: 0 solid;
	border-radius: 0;
	color: currentColor;
	cursor: pointer;
	padding: 0;
	text-decoration: underline;
}

.artwork-carousel__item-meta__form-toggle:focus,
.artwork-carousel__item-meta__form-toggle:hover {
	color: var(--color-foreground-hover);
}

.artwork-carousel__item-meta__form-toggle:focus:not(:focus-visible) {
	outline: 0;
}

.artwork-carousel__item-meta__form-toggle:hover {
	text-decoration: none;
}

/* Meta — Form. */
.artwork-carousel__item-meta__form {
	display: none; /* Can be shown by inline JS button. */
}

/* Form states. */
.artwork-carousel__item-meta__form [error-area],
.artwork-carousel__item-meta__form [progress-area],
.artwork-carousel__item-meta__form [success-area] {
	display: none;
	margin-top: var(--space-20);
}

.artwork-carousel__item-meta__form[data-state="errored"] [error-area] {
	display: block;
}

.artwork-carousel__item-meta__form[data-state="success"] [success-area] {
	display: block;
}

.artwork-carousel__item-meta__form[data-state="submitting"] [progress-area] {
	display: block;
}

.artwork-carousel__item-meta__form[data-state="submitting"] > *:not([progress-area]) {
	opacity: 0.5;
	transition: opacity 96ms linear;
}

/* Thumbnail Container. */
@media (min-width: 1280px) {
	.artwork-carousel__item-thumbnail-container {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 75%;
		height: 100%;
	}
}

/* Thumbnail. */
.artwork-carousel__item-thumbnail {
	width: 100%;
	height: auto;
}

@media (max-width: 1279px) {
	.artwork-carousel__item-thumbnail {
		padding-right: 8px;
		padding-left: 8px;
	}
}

@media (min-width: 1280px) {
	.artwork-carousel__item-thumbnail {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: auto;
		height: 100%;
	}
}

/* Thumbnail — Modifiers. */
@media (min-width: 1280px) {
	.artwork-carousel__item-thumbnail--fill-width {
		width: 100%;
		height: auto;
	}
}

/* Previous, Next & Close. */
.artwork-carousel__next,
.artwork-carousel__previous {
	appearance: none;
	background-color: transparent;
	border: 0 solid;
	border-radius: 0;
	color: currentColor;
	cursor: pointer;
	padding: 0;
	position: absolute;
	width: 3.75rem;
	height: 3.75rem;
	z-index: 2;
}

.artwork-carousel__next *,
.artwork-carousel__previous * {
	pointer-events: none;
}

@media (min-width: 768px) {
	.artwork-carousel__next,
	.artwork-carousel__previous {
		width: var(--space-50);
		height: var(--space-50);
	}
}

@media (min-width: 1440px) {
	.artwork-carousel__next,
	.artwork-carousel__previous {
		width: var(--space-100);
		height: var(--space-100);
	}
}

.artwork-carousel__next:focus:not(:focus-visible) {
	outline: 0;
}

.artwork-carousel__previous:focus:not(:focus-visible) {
	outline: 0;
}

/* Next. */
.artwork-carousel__next {
	top: 0;
	right: 0;
}

@media (min-width: 768px) {
	.artwork-carousel__next {
		top: 50%;
		transform: translateY(-50%);
	}
}

/* Previous. */
.artwork-carousel__previous {
	top: 0;
	left: 0;
}

@media (min-width: 768px) {
	.artwork-carousel__previous {
		top: 50%;
		transform: translateY(-50%);
	}
}

/* Previous + Next — Icons. */
.artwork-carousel__next__icon,
.artwork-carousel__previous__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: var(--space-20);
	height: var(--space-20);
}

@media (min-width: 1440px) {
	.artwork-carousel__next__icon,
	.artwork-carousel__previous__icon {
		width: var(--space-40);
		height: var(--space-40);
	}
}

/* Previous + Next — Icons — SVG. */
.artwork-carousel__next__icon svg,
.artwork-carousel__previous__icon svg {
	display: block;
	width: 100%;
	height: 100%;
}

.artwork-carousel__previous__icon svg {
	transform: rotate(-180deg);
}
