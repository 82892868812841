.landing-exhibitions {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

@media (min-width: 568px) {
	.landing-exhibitions {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

/* Item. */
@media (min-width: 568px) {
	.landing-exhibitions__item {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
		width: calc((100% - var(--space-20)) / 2);
	}
}

@media (min-width: 768px) {
	.landing-exhibitions__item {
		width: calc((100% - var(--space-40)) / 2);
	}
}

@media (max-width: 567px) {
	.landing-exhibitions__item + .landing-exhibitions__item {
		margin-top: var(--space-50);
	}
}

/* Header. */
.landing-exhibitions__item__header {
	box-sizing: border-box;
	padding-bottom: var(--space-50);
}

@media (min-width: 568px) {
	.landing-exhibitions__item__header {
		min-height: 78px;
	}
}

@media (min-width: 1280px) {
	.landing-exhibitions__item__header {
		padding-bottom: var(--space-100);
		min-height: 136px;
	}
}
