.overlay {
	background-color: var(--color-background);
	box-sizing: border-box;
	content-visibility: hidden;
	display: none;
	opacity: 0;
	overflow: hidden;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: var(--z-index-overlay);
}

.overlay[data-state="opening"],
.overlay[data-state="open"],
.overlay[data-state="closing"] {
	content-visibility: visible;
	display: block;
	opacity: 1;
	pointer-events: auto;
}

.overlay:focus {
	outline: none;
}

.overlay__container {
	position: relative;
	z-index: 1;
}

/* Styles for styleguide partial. */
.l-styleguide .overlay {
	content-visibility: visible;
	display: block;
	opacity: 1;
	pointer-events: auto;
	position: relative;
}
